export const baseStaticS3Url = 'https://travauxlib-espace-pro-prod.s3.amazonaws.com/static';

export const cgsOffres = `${baseStaticS3Url}/2023-CGS-hemea-Conditions-G%C3%A9n%C3%A9rales-de-Service-Offres.pdf`;
export const cgsOffresWithCouverture = `${baseStaticS3Url}/hemea-CGS-Offres-2023-avec-couverture-de-service.pdf`;
export const cgvArchi = `${baseStaticS3Url}/2023-CGV-hemea-Conditions-G%C3%A9n%C3%A9rales-de-Vente-Client-x-Architecte.pdf`;
export const cgvPrestataire = `${baseStaticS3Url}/2023-CGV-hemea-Conditions-G%C3%A9n%C3%A9rales-de-Vente-Client-x-Prestataire.pdf`;
export const cgsPrestataire = `${baseStaticS3Url}/2023-CGS-Novare-Construction-Conditions-G%C3%A9n%C3%A9rales-de-Services-hemea-x-Prestataire.pdf`;
export const cgsArchi = `${baseStaticS3Url}/2023-CGS-Novare-Construction-Conditions-G%C3%A9n%C3%A9rales-de-Services-hemea-x-Architecte.pdf`;
export const cguvClubPro = `${baseStaticS3Url}/2023-CGUV-Novare-Construction-Conditions-G%C3%A9n%C3%A9rales-d%E2%80%99Utilisation-et-de-Vente-Club-Pro.pdf`;

export const chartePro = `${baseStaticS3Url}/hemea_Charte-pro.pdf`;

export const avantagesCEHemea = `${baseStaticS3Url}/avantages-ce.pdf`;
